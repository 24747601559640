import './vendor/focus-visible.js';

import {tns} from '../../node_modules/tiny-slider/src/tiny-slider.module';

import '../../node_modules/lightbox2/dist/js/lightbox-plus-jquery';

let reviewsSlider = document.querySelector(".reviews-slider");

if (reviewsSlider) {
  var slider = tns({
    container: '.reviews-slider',
    items: 3,
    controls: false,
    nav: false,
    autoplayButton: false,
    autoplayButtonOutput: false,
    slideBy: 1,
    autoplay: true,
    speed: 1200,
    autoplayTimeout: 2500,
    mouseDrag: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1800: {
        items: 3,
      }
    }
  });
}

let introSlider = document.querySelector(".intro-slider");

if (introSlider) {
  var slider = tns({
    container: '.intro-slider',
    items: 1,
    controls: false,
    nav: false,
    autoplayButton: false,
    autoplayButtonOutput: false,
    slideBy: 1,
    autoplay: true,
    speed: 4000,
    autoplayTimeout: 8000,
    mouseDrag: true,
    loop: true,
    gutter: 60
  });
}



