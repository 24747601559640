let burgerMenu = document?.querySelector('.burger-menu');
let header = document?.querySelector('.header');
let headerHidden = document?.querySelector('.header-hidden');
let headerHiddenLinks = document?.querySelectorAll('.header-hidden__link');
let headerHiddenTopLinks = document?.querySelectorAll('.header-hidden-top__link');

burgerMenu?.addEventListener('click', (e) => {
  burgerMenu.classList.toggle('burger-menu_active');
  header.classList.toggle('header_active');
  headerHidden.classList.toggle('header-hidden_active');
  document.body.classList.toggle('hidden');
})

headerHiddenLinks.forEach(e => e.addEventListener('click', () => {
  burgerMenu.classList.remove('burger-menu_active');
  header.classList.remove('header_active');
  headerHidden.classList.remove('header-hidden_active');
  document.body.classList.remove('hidden');
}))

headerHiddenTopLinks.forEach(e => e.addEventListener('click', () => {
  burgerMenu.classList.remove('burger-menu_active');
  header.classList.remove('header_active');
  headerHidden.classList.remove('header-hidden_active');
  document.body.classList.remove('hidden');
}))
