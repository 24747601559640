let helpButton = document?.querySelector('.help__item_question');
let questionModal = document?.querySelector('.question-modal');
let questionClose = document?.querySelector('.question-modal__close');

helpButton?.addEventListener('click', (e) => {
  questionModal.classList.add('question-modal_active');
})

questionClose?.addEventListener('click', (e) => {
  questionModal.classList.remove('question-modal_active');
})

let BokkingButton = document?.querySelector('.booking-button');
let bookingModal = document?.querySelector('.booking-modal');
let bookingClose = document?.querySelector('.booking-modal__close');

BokkingButton?.addEventListener('click', (e) => {
  bookingModal.classList.add('booking-modal_active');
})

bookingClose?.addEventListener('click', (e) => {
  bookingModal.classList.remove('booking-modal_active');
})
