const tabsBtn   = document?.querySelectorAll(".advantages-menu__item");
const tabsItems = document?.querySelectorAll(".advantages-description__text");

tabsBtn.forEach(onTabClick);

function onTabClick(item) {
    item.addEventListener("click", function() {
        let currentBtn = item;
        let tabId = currentBtn.getAttribute("data-tab");
        let currentTab = document.querySelector(tabId);

        if( ! currentBtn.classList.contains('advantages-menu__item_active') ) {
            tabsBtn.forEach(function(item) {
                item.classList.remove('advantages-menu__item_active');
            });

            tabsItems.forEach(function(item) {
                item.classList.remove('advantages-description__text_active');
            });

            currentBtn.classList.add('advantages-menu__item_active');
            currentTab.classList.add('advantages-description__text_active');
        }
    });
}

// document?.querySelector('.advantages-menu__item').click();
